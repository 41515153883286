export type RemoteData<A> =
    | RemoteData.Pending
    | RemoteData.LoadComplete<A>
    | RemoteData.LoadError;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace RemoteData {
    export interface Initial {
        readonly kind: 'initial';
    }
    // eslint-disable-next-line @typescript-eslint/no-redeclare
    export const Initial: Initial = { kind: 'initial' };

    export interface Loading {
        readonly kind: 'loading';
    }
    // eslint-disable-next-line @typescript-eslint/no-redeclare
    export const Loading: Loading = { kind: 'loading' };

    export interface LoadComplete<A> {
        readonly kind: 'loadComplete';
        readonly value: A;
    }
    // eslint-disable-next-line @typescript-eslint/no-redeclare
    export const LoadComplete = <A>(value: A): LoadComplete<A> => ({
        kind: 'loadComplete',
        value,
    });

    export interface LoadError {
        readonly kind: 'loadError';
        readonly status: number;
        readonly message: string;
    }
    // eslint-disable-next-line @typescript-eslint/no-redeclare
    export const LoadError = (status: number, message: string): LoadError => ({
        kind: 'loadError',
        status,
        message,
    });

    export const isLoadComplete = <A>(
        data: RemoteData<A>,
    ): data is LoadComplete<A> => data.kind === 'loadComplete';

    export type Pending = Initial | Loading;

    export const isPending = <A = never>(
        data: RemoteData<A>,
    ): data is Pending => data.kind === 'initial' || data.kind === 'loading';

    export const isInitial = <A = never>(
        data: RemoteData<A>,
    ): data is Initial => data.kind === 'initial';

    export const isLoading = <A = never>(
        data: RemoteData<A>,
    ): data is Loading => data.kind === 'loading';
}
