import React, { useCallback } from 'react';
import { Modal, Button } from '@fabric-ds/react';
import { useConfig } from '../ConfigProvider';

type LoginProps = {
    isModalOpen: boolean;
    closeModal: () => void;
};

function LoginModal({ closeModal, isModalOpen }: LoginProps) {
    const { loginUrl } = useConfig();
    let currentUrl = null;
    if (typeof window !== "undefined") {
        currentUrl = window.location?.href
    }
    const loginUrlWithRedirect = `${loginUrl}${encodeURIComponent(
        `${currentUrl}#realestate-sold-module-podlet`,
    )}`;

    const setFocusRef = useCallback((element: any) => {
        if (element !== null) {
            element.focus();
        }
    }, []);

    return (
        <Modal
            open={isModalOpen}
            onDismiss={closeModal}
            title="Logg inn"
            footer={
                <>
                    <Button secondary onClick={closeModal}>
                        Ikke nå
                    </Button>
                    <a
                        className="button button--cta"
                        style={{ marginLeft: '12px' }}
                        href={loginUrlWithRedirect}
                        ref={setFocusRef}
                    >
                        Logg inn
                    </a>
                </>
            }
        >
            <p>
                Logg inn for å sende spørsmål om salget av denne eiendommen til
                megler.
            </p>
        </Modal>
    );
}

export default LoginModal;
