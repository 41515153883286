import { useCallback, useEffect, useState } from 'react';

const useSessionStorage = <A>(
    key: string,
    defaultValue: A,
): [A, (newValue: A) => void] => {
    const [value, setValue] = useState<A>(defaultValue);

    useEffect(() => {
        let item =  null;
        if (typeof window !== "undefined") {
            item = window.sessionStorage.getItem(key)
        }

        let parsedItem: A;
        if (item) {
            try {
                parsedItem = JSON.parse(item);
            } catch (e: unknown) {
                parsedItem = defaultValue;
            }
        } else {
            parsedItem = defaultValue;
        }

        setValue(parsedItem);
    }, []);

    const updater = useCallback(
        (newValue: A): void => {
            setValue(newValue);
            if (typeof window !== "undefined") {
                window.sessionStorage.setItem(key, JSON.stringify(newValue));
            }
        },
        [key],
    );

    return [value, updater];
};

export default useSessionStorage;
