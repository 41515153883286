import { IconChevronDown16 } from '@fabric-ds/icons/react';
import React from 'react';
import classes from './HalfMoonButton.module.css';

type HalfMoonButtonProps = {
    id: string;
    isOpen: boolean;
    toggleForm: (open: boolean, isArrow: boolean) => void;
};

function HalfMoonButton({ id, isOpen, toggleForm }: HalfMoonButtonProps) {
    return (
        <>
            <button
                id={id}
                className={`${classes.halfMoonButton} ${
                    isOpen
                        ? classes.halfMoonButtonUp
                        : classes.halfMoonButtonDown
                }`}
                onClick={() => {
                    toggleForm(!isOpen, true);
                }}
                aria-label={
                    isOpen
                        ? 'Lukk skjema'
                        : 'Still spørsmål til megler, vis skjema'
                }
            >
                <IconChevronDown16
                    className={`${classes.halfMoonButtonIcon} inline`}
                />
            </button>
        </>
    );
}

export default HalfMoonButton;
