import pulse from '@finn-no/pulse-sdk';

export enum Event {
    AskButtonOrArrow,
    ActionButton,
    HomePageLink,
    MoreAdsLink,
    Link,
    PhoneNo,
}

interface EventData {
    targetType: 'ExternalContent' | 'Listing' | 'PhoneContact' | null;
    eventReference: string;
    rootName: string;
    objectType: string;
}

const eventTypeValue: (e: Event) => EventData | null = (e: Event) => {
    switch (e) {
        case Event.AskButtonOrArrow:
            return {
                eventReference: 'RE_ev30',
                rootName: 'Ask realtor about sale',
                objectType: 'Button',
                targetType: null,
            };
        case Event.ActionButton:
            return {
                eventReference: 'RE_ev37',
                rootName: 'Click on sold module action button',
                objectType: 'Button',
                targetType: 'ExternalContent',
            };

        case Event.HomePageLink:
            return {
                eventReference: 'RE_ev38',
                rootName: 'Click on sold module home page link',
                objectType: 'Link',
                targetType: 'ExternalContent',
            };
        case Event.MoreAdsLink:
            return {
                eventReference: 'RE_ev39',
                rootName: 'Click on sold module more ads link',
                objectType: 'Link',
                targetType: 'Listing',
            };
        case Event.Link:
            return {
                eventReference: 'RE_ev40',
                rootName: 'Click on sold module link',
                objectType: 'Link',
                targetType: 'ExternalContent',
            };
        case Event.PhoneNo:
            return {
                eventReference: 'RE_ev41',
                rootName: 'Click on sold module phone no',
                objectType: 'Link',
                targetType: 'PhoneContact',
            };
        default:
            return null;
    }
};

type TrackProps = {
    eventType: Event;
    orgName: string;
    pageName?: string | false;
    objectTypeP?: string | false;
    trackingSubvertical?: string;
};

export const track =
    ({
        eventType,
        orgName,
        pageName,
        objectTypeP,
        trackingSubvertical,
    }: TrackProps) =>
    () => {
        const event = eventTypeValue(eventType)!;
        const objectType = objectTypeP || event.objectType;
        // @ts-expect-error target.type is not allowed to be PhoneContact, but valid in schema and excel spec
        pulse.trackEvent({
            type: 'Click',
            intent: 'Open',
            name: event.rootName,
            object: {
                id: `sold-state-link`,
                type: 'UIElement',
                elementType: objectType,
                hasExtendedProfile: true,
            },
            ...(event.targetType && {
                target: {
                    id: 'sold-state-link', // type is required, but not in spec
                    type: event.targetType,
                    name: pageName || 'Go to webpage',
                    referralCommission: false,
                    provider: orgName,
                },
            }),
            vertical: {
                name: 'realestate',
                ...(trackingSubvertical && {
                    subVertical: trackingSubvertical,
                }),
            },
        });
    };
