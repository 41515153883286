import React from 'react';
import type { Link } from '../types';
import classes from './ProfileExpanded.module.css';
import { track, Event } from '../tracking';

type ProfileLinkProps = {
    link?: Link;
    trackF?: () => void;
};

function ProfileLink({ link, trackF = () => null }: ProfileLinkProps) {
    if (!link?.url || !link?.text) {
        return null;
    }
    return (
        <p className="mt-16">
            <a className="soldStateLink" href={link.url} onClick={trackF}>
                {link.text}
            </a>
        </p>
    );
}

type ProfileExpandedProps = {
    logoUrl?: string;
    slogan?: string;
    homepageUrl?: string;
    moreAdsFromLink?: string;
    customLinks: Link[];
    actionLink?: Link;
    orgName: string;
    trackingSubvertical?: string;
};

function ProfileExpanded({
    logoUrl,
    slogan,
    homepageUrl,
    moreAdsFromLink,
    customLinks,
    actionLink,
    orgName,
    trackingSubvertical,
}: ProfileExpandedProps) {
    return (
        <div
            className={`${classes.profileExpandedContainer} rounded-8 mt-32 overflow-hidden`}
        >
            {logoUrl && (
                <div
                    className={`${classes.profileExpandedLogo} flex items-center justify-center p-16`}
                >
                    {logoUrl && (
                        <img
                            className="object-contain object-center"
                            src={logoUrl}
                            alt="Logo"
                        />
                    )}
                </div>
            )}
            <hr className={`${classes.profileExpandedLogoDivider}`} />
            <div className={`${classes.profileExpandedContent} py-16 px-16`}>
                {slogan && <h3 className="text-16">{slogan}</h3>}
                {homepageUrl && (
                    <ProfileLink
                        link={{ text: 'Hjemmeside', url: homepageUrl }}
                        trackF={track({
                            eventType: Event.HomePageLink,
                            orgName,
                            trackingSubvertical,
                        })}
                    />
                )}
                {moreAdsFromLink && (
                    <ProfileLink
                        link={{
                            text: 'Flere annonser fra annonsør',
                            url: moreAdsFromLink,
                        }}
                        trackF={track({
                            eventType: Event.MoreAdsLink,
                            orgName,
                            trackingSubvertical,
                            pageName: 'Flere annonser fra annonsør',
                        })}
                    />
                )}
                {customLinks.map((l: Link, idx: number) => (
                    <ProfileLink
                        key={idx}
                        link={l}
                        trackF={track({
                            eventType: Event.Link,
                            orgName,
                            trackingSubvertical,
                        })}
                    />
                ))}
                {actionLink?.url && (
                    <a
                        href={actionLink.url}
                        className={`${classes.ctaButton} button w-full max-w-full`}
                        onClick={track({
                            eventType: Event.ActionButton,
                            orgName,
                            trackingSubvertical,
                        })}
                    >
                        {actionLink.text}
                    </a>
                )}
            </div>
        </div>
    );
}

export default ProfileExpanded;
