import React, { useContext } from 'react';
import type { AppConfig } from './types';

export const ConfigContext = React.createContext<AppConfig>({
    minFinnUrl: '',
    loginUrl: '',
    mailPostUrl: '',
    userProfileUrl: '',
});

export const useConfig = () => useContext(ConfigContext);
