import React from 'react';
import SoldState from './components/SoldState';
import { ConfigContext } from './ConfigProvider';
import type { AppProps } from './types';

export default function App(props: AppProps) {
    return (
        <React.StrictMode>
            <ConfigContext.Provider value={props.config}>
                <SoldState
                    adId={props.adId}
                    basicProfile={props.basicProfile}
                    soldState={props.soldState}
                    trackingSubvertical={props.trackingSubvertical}
                />
            </ConfigContext.Provider>
        </React.StrictMode>
    );
}
