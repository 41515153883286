import pulse from '@finn-no/pulse-sdk';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextArea, TextField, Toggle } from '@fabric-ds/react';
import type { ToggleEntry } from 'toggle/src/props';
import useFormPersist from 'react-hook-form-persist';
import { useConfig } from '../ConfigProvider';
import { RemoteData } from '../LoadingState';
import LoginModal from './LoginModal';

type ContactFormType = 'PHONE' | 'EMAIL';
type SoldStateForm = {
    adId: number;
    userPhone?: string;
    contactForm: ContactFormType;
    questions: (string | boolean)[];
    other: string | undefined;
    userName?: string;
};

type UserProfile = {
    userId: number;
    email: string;
    mobile?: string;
    userName?: string;
};

type FormProps = {
    adId: number;
    formSuccessCallback: () => void;
    definedQuestions: ToggleEntry[];
    toast: (message: string, opts: any) => void;
    trackingSubvertical?: string;
};

const getSessionFromStorage = (sessionStorageKey: string) => {
    try {
        return JSON.parse(sessionStorage.getItem(sessionStorageKey) || '{}');
    } catch (e) {
        // eslint-disable-next-line no-console
        console.info('Problem getting from storage', e);
        return {};
    }
};

const clearStorage = (sessionStorageKey: string) => {
    try {
        sessionStorage.removeItem(sessionStorageKey);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.info('Removing item from storage', e);
    }
};

function Form(props: FormProps) {
    const { definedQuestions, toast, adId, formSuccessCallback } = props;
    const { minFinnUrl, mailPostUrl, userProfileUrl } = useConfig();

    const annetOption = { label: 'Annet', value: 'annet' };
    const questionsWithAnnet = [...definedQuestions, annetOption];

    const phone = { value: 'PHONE', label: 'Svar meg på telefon' };
    const contactValues = [
        phone,
        { value: 'EMAIL', label: 'Svar meg på e-post' },
    ];

    const annetTextIndex = questionsWithAnnet.length + 1;

    const sessionStorageKey = `__soldState[${adId}]`;
    const session = getSessionFromStorage(sessionStorageKey);

    const defaultSelected =
        session.questions
            ?.map((v: string) => questionsWithAnnet.find((q) => q.value === v))
            .filter((e: ToggleEntry | undefined) => e) || [];

    const defaultContact =
        contactValues.find((c) => c.value === session.contactForm) || phone;

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        watch,
        formState: { errors },
    } = useForm<SoldStateForm>({
        defaultValues: {
            adId,
            questions: defaultSelected.map((e: ToggleEntry) => e.value),
            contactForm: defaultContact.value as ContactFormType,
            userPhone: undefined,
            userName: '',
        },
    });

    useFormPersist(sessionStorageKey, { watch, setValue });

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const [contactForm, setContactForm] =
        React.useState<ToggleEntry>(defaultContact);

    const [selected, setSelected] =
        React.useState<ToggleEntry[]>(defaultSelected);

    const [userProfile, setUserProfile] = useState<RemoteData<UserProfile>>(
        RemoteData.Initial,
    );
    const [errorMessage, setErrorMessage] = useState<string>();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const hasOther = selected.find((e) => e.value === annetOption.value);

    const closeModal = () => setModalOpen(false);

    const validateResponseOK = (response: Response): Promise<Response> =>
        response.ok ? Promise.resolve(response) : Promise.reject(response);

    useEffect(() => {
        setUserProfile(RemoteData.Loading);
        fetch(userProfileUrl, { credentials: 'same-origin' })
            .then(validateResponseOK)
            .then((resp) => resp.json())
            .then((up) => {
                setUserProfile(RemoteData.LoadComplete(up));
                setValue('userName', up.userName);
            })
            .catch((e) => {
                if (e instanceof Response && e.status === 403) {
                    setUserProfile(
                        RemoteData.LoadError(e.status, 'user not logged in'),
                    );
                } else {
                    setUserProfile(RemoteData.LoadError(e.status, e.message));
                    setErrorMessage(
                        'Noe gikk galt under lasting av profilen din. Prøv igjen senere.',
                    );
                }
            });
    }, []);

    const hasFormErrors = () =>
        errors.questions?.message ||
        errors.questions?.[annetTextIndex]?.message ||
        errors.userPhone?.message ||
        errors.userName?.message;

    const onSubmit = (data: SoldStateForm) => {
        if (
            RemoteData.isLoadComplete(userProfile) &&
            Boolean(userProfile.value)
        ) {
            const byPhoneOrEmail =
                data.contactForm === 'EMAIL' ? 'by_email' : 'by_phone';

            const sorted = questionsWithAnnet.filter((q) =>
                selected.find((s) => s.value === q.value),
            );

            const trackingName = `${byPhoneOrEmail}: ${sorted
                .map((s) => s.value)
                .join(', ')}${', nameAdded'}`;

            pulse.trackEvent({
                name: 'Ask realtor about sale',
                type: 'Click',
                intent: 'Send',
                object: {
                    id: 'ask-realtor-about-sale',
                    type: 'UIElement',
                    elementType: 'Button',
                    name: trackingName,
                    vertical: {
                        name: 'realestate',
                        ...(props.trackingSubvertical && {
                            subVertical: props.trackingSubvertical,
                        }),
                    },
                },
            });

            const questions = [
                ...sorted
                    .filter((s) => s.value !== annetOption.value)
                    .map((s) => s.label),
                ...(data.other ? [data.other] : []),
            ];

            const params = {
                adId: data.adId,
                contactForm: data.contactForm,
                ...(contactForm.value === 'PHONE' && {
                    userPhone: data.userPhone || userProfile.value.mobile,
                }),
                userName: data.userName?.trim(),
                questions,
            };
            setIsSubmitting(true);
            fetch(mailPostUrl, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            })
                .then(validateResponseOK)
                .then(formSuccessCallback)
                .then(() => {
                    clearStorage(sessionStorageKey);
                    setIsSubmitting(false);
                })
                .catch(() => {
                    setIsSubmitting(false);
                    toast(
                        'Obs! Noe gikk galt under innsending av skjema. Vennligst prøv igjen senere.',
                        { type: 'error' },
                    );
                    setErrorMessage(
                        'Noe gikk galt under innsending av skjema. Vennligst prøv igjen senere.',
                    );
                });
        } else {
            setModalOpen(true);
        }
    };

    const validateMinChecked = (
        newSelected: ToggleEntry[] | undefined = undefined,
    ) => (newSelected || selected).length >= 1;

    const minCheckedError =
        'Du kan ikke sende et tomt skjema til megler. Velg ett eller flere spørsmål.';

    const validateMinCheckedError = () =>
        validateMinChecked() || minCheckedError;

    function validateSelected(
        newSelected: ToggleEntry[] | undefined = undefined,
    ) {
        if (validateMinChecked(newSelected || selected)) {
            clearErrors('questions');
        } else {
            setError('questions', {
                type: 'manual',
                message: minCheckedError,
            });
        }
    }

    const handleSelect = (entry: ToggleEntry) => {
        let newSelected: ToggleEntry[];
        if (selected.some((e) => e.value === entry.value)) {
            newSelected = selected.filter((s) => s.value !== entry.value);
        } else {
            newSelected = [...selected, entry];
        }
        setSelected(newSelected);
        setValue(
            'questions',
            newSelected.map((s) => s.value as string),
        );
        validateSelected(newSelected);
    };

    const otherTextWL = watch(`other`)?.length || 0;

    const handleContactFormChange = (contactChange: ToggleEntry) => {
        setValue('contactForm', contactChange.value as ContactFormType);
        setContactForm(contactChange);
    };

    const howToContact = (): JSX.Element => {
        if (!RemoteData.isLoadComplete(userProfile)) {
            return <></>;
        }

        const isEmail = contactForm.value === 'EMAIL';
        const isPhoneFromProfile = userProfile.value.mobile && !isEmail;
        const isPhoneUnknown = !userProfile.value.mobile && !isEmail;

        return (
            <>
                <h3 className="mt-32 mb-8">Hvordan kan vi kontakte deg?</h3>
                <div className="radioEmailPhone">
                    <Toggle
                        type="radio"
                        className="mr-16"
                        defaultSelected={[defaultContact]}
                        options={contactValues}
                        {...register('contactForm')}
                        onChange={(e) => handleContactFormChange(e)}
                    />
                </div>
                <p className="mt-16 text-14">
                    Meldingen din vil bli sendt som e-post til megler, med
                    beskjed om at du ønsker å bli kontaktet på{' '}
                    {isEmail ? 'e-post' : 'telefon'}.
                </p>
                {isEmail && (
                    <div className="mt-16">
                        <h4>Din e-postadresse</h4>
                        <p>{userProfile.value.email}</p>
                    </div>
                )}
                {isPhoneFromProfile && (
                    <div className="mt-16">
                        <h4>Ditt telefonnummer</h4>
                        <p>{userProfile.value.mobile}</p>
                    </div>
                )}
                {isPhoneUnknown && (
                    <div
                        className={
                            errors.userPhone
                                ? 'formValidationError'
                                : 'formValidationOk'
                        }
                        style={
                            errors.userPhone
                                ? {
                                      paddingBottom: '8px',
                                  }
                                : {
                                      paddingBottom: '30px',
                                  }
                        }
                    >
                        <TextField
                            type="tel"
                            label="Telefon"
                            className="pt-16"
                            {...register('userPhone', {
                                required:
                                    'Skriv inn et gyldig telefonnummer. Må bestå av 8 siffer.',
                                pattern: {
                                    value: /^[0-9]{8}$/,
                                    message:
                                        'Skriv inn et gyldig telefonnummer. Må bestå av 8 siffer.',
                                },
                            })}
                            helpText={
                                errors.userPhone
                                    ? errors.userPhone?.message
                                    : null
                            }
                            error={Boolean(errors.userPhone?.message)}
                        />
                    </div>
                )}

                <div
                    className={
                        errors.userName
                            ? 'formValidationError'
                            : 'formValidationOk'
                    }
                    style={
                        errors.userName
                            ? {
                                  paddingBottom: '8px',
                              }
                            : {
                                  paddingBottom: '30px',
                              }
                    }
                >
                    <TextField
                        type="text"
                        label="Navn"
                        className="pt-16"
                        {...register('userName', {
                            required: 'Skriv inn navnet ditt.',
                        })}
                        helpText={
                            errors.userName ? errors.userName?.message : null
                        }
                        error={Boolean(errors.userName?.message)}
                    />
                </div>

                {(isEmail || isPhoneFromProfile) && (
                    <p className="mt-16">
                        <a className="soldStateLink" href={minFinnUrl}>
                            {isEmail
                                ? 'Du kan endre e-postadressen i FINN-profilen din.'
                                : 'Du kan endre telefonnummer i FINN-profilen din.'}
                        </a>
                    </p>
                )}
            </>
        );
    };

    const noQuestionsMarked = errors.questions?.message && !hasOther;
    return (
        <form
            className="post-form"
            onSubmit={handleSubmit(onSubmit, () => validateSelected())}
        >
            <input
                id="adId"
                type="hidden"
                defaultValue={adId}
                {...register('adId')}
            />
            <h3>Hva lurer du på?</h3>
            <div
                className={
                    noQuestionsMarked
                        ? 'formValidationError'
                        : 'formValidationOk'
                }
            >
                <Toggle
                    type="checkbox"
                    className="my-8"
                    options={questionsWithAnnet}
                    defaultSelected={defaultSelected}
                    {...register(`questions`, {
                        validate: {
                            minChecked: () => validateMinCheckedError(),
                        },
                    })}
                    onChange={(e) => handleSelect(e)}
                />

                {hasOther && (
                    <>
                        <TextArea
                            maxLength={300}
                            minimumRows={2}
                            {...register(`other`, {
                                required:
                                    'Du har valgt “Annet” men har ikke fylt inn et spørsmål til megler.',
                                maxLength: {
                                    value: 300,
                                    message: 'Maksimalt 300 tegn',
                                },
                            })}
                            helpText={
                                errors.other?.message
                                    ? errors.other.message
                                    : `${otherTextWL} / 300 tegn`
                            }
                            error={Boolean(errors.other?.message)}
                        />
                        <p className="text-14 mt-8">
                            FINN.no forbeholder seg retten til å kontrollere og
                            stoppe meldinger.
                        </p>
                    </>
                )}
            </div>
            {noQuestionsMarked && (
                <div className="text-12 text-red-600" role="alert">
                    Obs! Du kan ikke sende et tomt skjema til megler. Velg ett
                    eller flere spørsmål.{' '}
                </div>
            )}

            {howToContact()}

            <p className="mt-32 mb-16 text-14">
                Etter at du trykker &quot;Send skjema&quot; vil FINN sende
                spørsmålene og kontaktopplysningene i skjemaet til megler.
            </p>
            <Button
                primary
                disabled={RemoteData.isInitial(userProfile)}
                loading={RemoteData.isLoading(userProfile) || isSubmitting}
                type="submit"
                id="ctaButtonB"
            >
                Send skjema
            </Button>
            <LoginModal closeModal={closeModal} isModalOpen={isModalOpen} />
            {(hasFormErrors() || errorMessage) && (
                <div
                    className="mt-16 p-16 bg-red-100 rounded-8"
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <span
                        className="mr-8"
                        style={{ width: '16px', height: '16px' }}
                    ></span>
                    <span className="text-gray-600">
                        Obs! {errors.questions?.message}{' '}
                        {errors.questions?.[annetTextIndex]?.message}{' '}
                        {errors.userPhone?.message} {errorMessage}
                        {errors.userName?.message} {errorMessage}
                    </span>
                </div>
            )}
        </form>
    );
}

export default Form;
